<template>
  <div>
    <ManageProduct />
  </div>
</template>
<script>
import ManageProduct from "@/components/Product/ManageProduct";
export default {
  components: {
    ManageProduct,
  },
  created() {
  },
};
</script>