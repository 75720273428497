<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>จัดการสินค้า</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มสินค้า</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersproduct"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="viewProduct(item)">mdi-eye</v-icon>
                <v-icon @click="UpdateProduct(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteProduct(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      headersproduct: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รหัสสินค้า", value: "sku", align: "center" },
        { text: "ชื่อ", value: "productName", align: "center", width: "400" },
        // { text: "รายละเอียด", value: "description", align: "center" },
        { text: "ราคาปลีก(บาท)", value: "price", align: "center" },
        { text: "ราคาแพ็ก(บาท)", value: "pricePack", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      // activeFlag: (...)
      // categoryId: (...)
      // count: 1
      // createdAt: (...)
      // description: (...)
      // id: (...)
      // imgUrl: (...)
      // price: (...)
      // pricePack: (...)
      // productName: "3"
      // sku: (...)
      // typeId: (...)
      // unit: (...)
      // unitPack: (...)
      // updatedAt: (...)
      items: [],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllProduct();
    for (let i in this.items) {
      this.items[i].count = parseInt(i) + 1;
      //console.log(this.list);
    }
  },

  methods: {
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products`
        // 'http://localhost:8081/types/'
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },

    goToCreate() {
      this.$router.push("createproduct");
    },
    viewProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("viewProduct");
    },
    UpdateProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("EditProduct");
    },
    async DeleteProduct(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(Decode.decode(localStorage.getItem("user")));
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/products/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllProduct();
        }
      });
    },
  },
};
</script>